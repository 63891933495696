<template>
  <div class="bank-account" :class="{skeleton: !state.loaded }">
    <div class="wrapper">
      <div class="title font-md">
        <b>출금 계좌 인증</b>
      </div>
      <div class="desc font-sm">아래 내용을 입력하신 후 인증하기 버튼을 눌러주세요.</div>
      <div class="content">
        <template v-if="state.step === 1">
          <div class="form-group">
            <label class="font-sm" :for="`${component.name}WithdrawAccountCode`">은행</label>
            <select :id="`${component.name}WithdrawAccountCode`" class="form-control font-sm border-focus-point" v-model="state.args1.withdrawAccountCode">
              <option value="">선택하세요.</option>
              <option :value="code.key" v-for="(code, idx) in $definitions.codes.arr.bankCodes" :key="idx">{{ code.value }}</option>
            </select>
          </div>
          <div class="form-group">
            <label class="font-sm" :for="`${component.name}WithdrawAccountNum`">계좌번호</label>
            <Number :id="`${component.name}WithdrawAccountNum`" :value.sync="state.args1.withdrawAccountNum" :allowZero="true" :noComma="true" placeholder='숫자만 입력해주세요("-" 제외).'/>
          </div>
        </template>
        <template v-else>
          <div class="alert font-sm">
            <div>입력하신 계좌로 1원을 이체하였습니다.</div>
            <div class="pb-2">입금자명에 표시된 인증 번호(4자리)를 입력해주세요.</div>
            <u class="color-danger">예시) OMC1234 → 1234 입력</u>
          </div>
          <div class="form-group">
            <div class="d-flex justify-content-between">
              <label class="font-sm" :for="`${component.name}AuthNo`">인증 번호</label>
              <div class="color-secondary font-xs">{{ computedRemainTime }}</div>
            </div>
            <Number :id="`${component.name}AuthNo`" :value.sync="state.args2.authNo" :allowZero="true" :noComma="true" :maxlength="4" placeholder="인증번호 4자리를 입력해주세요."/>
          </div>
        </template>
      </div>
    </div>
    <div class="action">
      <button class="btn btn-point btn-block font-sm" @click="submit()">인증하기</button>
    </div>
  </div>
</template>

<script>
import {computed, defineComponent, onUnmounted, reactive} from "@vue/composition-api";
import http from "@/scripts/http";
import store from "@/scripts/store";
import Number from "@/components/Number";
import mixin from "@/scripts/mixin";
import lib from "@/scripts/lib";
import definitions from "@/scripts/definitions";

function Component(initialize) {
  this.name = "modalBankAccount";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {Number},
  setup() {
    const component = new Component(() => {
      store.commit("tuneModal", {component, size: "sm"});
      state.args1.investorName = modalParams.investorName;
    });

    const state = reactive({
      step: 1,
      args1: {
        investorName: "",
        withdrawAccountCode: "",
        withdrawAccountNum: "",
      },
      timer: {
        remain: 0,
        interval: 0,
      },
      args2: {
        authNo: ""
      }
    });

    const computedRemainTime = computed(() => {
      const minutes = Math.floor(state.timer.remain / 60);
      const seconds = state.timer.remain % 60;
      return `${lib.getNumberWithPadding(minutes)}:${lib.getNumberWithPadding(seconds)}`;
    });

    const modalParams = store.getters.modalParams(component);

    const runTimer = () => {
      clearInterval(state.timer.interval);

      state.timer.remain = definitions.limits.maxInvestorAccountCodeSeconds;
      state.timer.interval = setInterval(() => {
        state.timer.remain -= 1;

        if (!state.timer.remain) {
          store.commit("setSwingMessage", "인증 코드가 만료되었습니다. 다시 인증해주시기 바랍니다.");
          reset();
          return;
        }
      }, 1000);
    };

    const reset = () => {
      state.step = 1;
      state.args2.authNo = "";
      clearInterval(state.timer.interval);
    };

    const submit = () => {
      if (state.step === 1) {
        if (!state.args1.investorName) {
          return store.commit("setSwingMessage", "투자자 이름을 입력해주세요.");
        } else if (!state.args1.withdrawAccountCode) {
          return store.commit("setSwingMessage", "은행을 선택해주세요.");
        } else if (!state.args1.withdrawAccountNum) {
          return store.commit("setSwingMessage", "계좌번호를 입력해주세요.");
        }

        if (modalParams.withdrawAccountNum?.toString() === state.args1.withdrawAccountNum?.toString() && modalParams.withdrawAccountCode?.toString() === state.args1.withdrawAccountCode?.toString()) {
          return store.commit("setSwingMessage", "기존 계좌 정보와 동일합니다.");
        }

        http.post("/api/invest/account/trans1", state.args1).then(() => {
          store.commit("setSwingMessage", "입력하신 계좌로 1원을 이체하였습니다.");
          state.step = 2;
          runTimer();
        });
      } else {
        if (!state.args2.authNo || state.args2.authNo.length !== 4) {
          return store.commit("setSwingMessage", "인증 코드 4자리를 입력해주세요.");
        }

        state.args2.authNo = state.args2.authNo.toString();

        const args = {...state.args1, ...state.args2};

        http.post("/api/investor/accounts/valid", args).then(() => {
          clearInterval(state.timer.interval);

          store.commit("closeModal", {
            name: component.name,
            onClose(modal) {
              store.dispatch("callback", {
                modal,
                params: {
                  withdrawAccountCode: state.args1.withdrawAccountCode,
                  withdrawAccountNum: state.args1.withdrawAccountNum
                }
              });

              store.commit("setSwingMessage", "출금 계좌 인증이 완료되었습니다.");
            }
          });
        }).catch((err) => {
          err?.response?.data?.code === "BAD_REQUEST_TIMEOUT_INVESTOR_AUTH_CODE" && reset();
        });
      }
    };

    onUnmounted(() => {
      clearInterval(state.timer.interval);
    });

    return {component, state, submit, computedRemainTime};
  },
});
</script>

<style lang="scss" scoped>
.bank-account {
  > .wrapper {
    background: #fff;
    padding: $px25;

    .title {
      margin-bottom: $px9;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .desc {
      margin-bottom: $px20;
      color: #666;

      p {
        margin-bottom: $px3;
      }
    }

    .content {
      .form-group {
        margin-bottom: $px25;

        select, input {
          height: $formHeight;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      .alert {
        border: $px1 solid #eee;
        background: $colorBackground;
        padding: $px15;
      }
    }
  }

  .action {
    button {
      padding: $px15;
      height: $px56;
      border-radius: 0;
    }
  }
}
</style>